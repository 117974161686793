<template>
  <div class="manage-stores">
    <h2>{{ $t("manageStores") }}</h2>
    <v-data-table
      :headers="headers"
      :items="data"
      :search="search"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      multi-sort
      class="mt-8 elevation-0"
    >
      <!-- Top Toolbar -->
      <template v-slot:top>
        <v-toolbar flat class="data-table-top-layout">
          <!-- Create Store Dialog -->
          <v-dialog
            v-model="formDialog"
            persistent
            max-width="500px"
            @click:outside="closeDialogs"
          >
            <template v-slot:activator="{ on }">
              <v-hover v-slot="{ hover }">
                <v-btn
                  color="white--text"
                  v-on="on"
                  :style="{ 'background-color': hover ? '#002147' : '#535353' }"
                  style="text-transform: unset !important"
                  >{{ $t("createStore") }}</v-btn
                >
              </v-hover>
            </template>
            <v-card>
              <v-container class="pa-4">
                <v-row class="mb-4">
                  <v-card-title class="text-h5">{{formTitle}}</v-card-title>
                  <v-spacer />
                  <v-btn
                    icon
                    color="primary"
                    class="mt-3"
                    @click="closeDialogs"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-row>
                <v-divider />
                <v-form @submit.prevent="formSubmit" class="mx-2">
                  <v-row>
                    <v-col cols="12" class="form-layout">
                      <a-text-field
                        v-model="$v.formInputCode.$model"
                        :validator="$v.formInputCode"
                         :label="$t('storeCode') + $t('required')"
                        class="mt-4"
                      />
                      <a-text-field
                        v-model="$v.formInputName.$model"
                        :validator="$v.formInputName"
                        :label="$t('storeName') + $t('required')"
                        class="mt-4"
                      />
                      <a-text-field
                        v-model="$v.formInputCity.$model"
                        :validator="$v.formInputCity"
                         :label="$t('city') + $t('required')"
                        placeholder="Ex: Lisbon, Portugal"
                        class="mt-4"
                      />
                      <a-text-field
                        v-model="$v.formInputLat.$model"
                        :validator="$v.formInputLat"
                        type="number"
                         :label="$t('latitude') + $t('required')"
                        class="mt-4"
                      />
                      <a-text-field
                        v-model="$v.formInputLng.$model"
                        :validator="$v.formInputLng"
                        :label="$t('longitude') + $t('required')"
                        type="number"
                        class="mt-4"
                      />
                      <a-select
                        v-model="$v.formInputCompany.$model"
                        :validator="$v.formInputCompany"
                        :items="companies"
                        itemtext="name"
                       :label="$t('selectCompany') + $t('required')"
                        class="mt-4"
                      >
                      </a-select>
                      <v-combobox
                        v-model="formInputAnalytics"
                        :items="analytics"
                        chips
                        clearable
                        :label="$t('selectAnalytics')"
                        multiple
                        solo
                        class="mt-8"
                      >
                        <template
                          v-slot:selection="{ attrs, item, select, selected }"
                        >
                          <v-chip
                            v-bind="attrs"
                            :input-value="selected"
                            close
                            @click="select"
                            @click:close="remove(item)"
                          >
                            <strong>{{ item }}</strong>
                          </v-chip>
                        </template>
                      </v-combobox>
                    </v-col>
                  </v-row>
                  <v-card-actions class="mt-4 mr-n4">
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="closeDialogs"
                      style="text-transform: unset !important"
                    >
                      {{$t('close')}}
                    </v-btn>
                    <v-btn
                      color="primary"
                      type="submit"
                      class="mr-2"
                      style="text-transform: unset !important"
                    >
                      {{$t('save')}}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-container>
            </v-card>
          </v-dialog>
          <!-- !Create Store Dialog -->
          <v-spacer />
          <v-col cols="3">
            <a-text-field
              v-model="search"
              :label="$t('search')"
              append-icon="mdi-magnify"
            ></a-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <!-- !Top Toolbar -->
      <!-- Customize Columns -->
      <template v-slot:[`item.files`]="{ item }">
        <div class="data-table-actions-layout">
          <v-btn
            small
            color="primary"
            outlined
            @click.stop="downloadFiles(item.files)"
            style="text-transform: unset !important"
            v-if="item.files.length > 0 && item.has_upload == false"
          >
            <v-icon small center>mdi-download</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="data-table-actions-layout">
          <!-- item.has_upload == false ? true : false -->
          <v-btn
            small
            color="primary"
             width="85%"
            class="mt-2 ml-1"
            @click.stop="importFileItem(item)"
            style="text-transform: unset !important"
            :disabled="
              item.files.length > 0 && item.has_upload == false ? false : true
            "
          >
            <v-icon small left>mdi-file</v-icon>
            {{$t('importFiles')}}
          </v-btn>
          <br />
          <v-hover v-slot="{ hover }">
            <v-btn
              small
              width="40%"
              color="white--text"
              class="ml-1 mr-2 mt-2 mb-2 px-8"
              style="text-transform: unset !important"
              :style="{ 'background-color': hover ? '#002147' : '#535353' }"
              @click.stop="editItem(item)"
            >
              <v-icon small left>mdi-pencil</v-icon>
              {{$t('edit')}}
            </v-btn>
          </v-hover>
          <v-btn
            small
            width="40%"
            class="mt-2 mb-2 px-8 ml-1"
            color="red lighten-1 white--text"
            style="text-transform: unset !important"
            @click.stop="deleteItem(item)"
          >
            <v-icon small left>mdi-delete</v-icon>
            {{$t('delete')}}
          </v-btn>
        </div>
      </template>
      <!-- !Customize Columns -->
    </v-data-table>
    <!-- Import File Dialog -->
    <v-dialog
      v-model="importFileDialog"
      width="400"
      @click:outside="closeImportFileDialog"
    >
      <v-card>
        <v-card-title class="text-h5"> {{$t('importFiles')}} </v-card-title>
        <v-divider />
        <v-card-text class="mt-2">
          <v-col>
            <v-file-input
              accept=".xlsx, .xls, .csv"
              class="ml-n4 mt-4"
              v-model="inputProductLocationsFile"
              show-size
              small-chips
              :label="$t('productLocationsFile')"
              prepend-icon="mdi-paperclip"
              hide-details="auto"
              @click="checkFile = ''"
              @change="inputChanged(inputProductLocationsFile, 'products')"
            ></v-file-input>
            <p class="red--text mt-2 ml-5" v-show="show">
              {{ message }}
            </p>
          </v-col>
          <v-col>
            <v-file-input
              accept=".xlsx, .xls, .csv"
              class="ml-n4"
              v-model="inputSalesDataFile"
              show-size
              small-chips
              :label="$t('salesDataFile')"
              prepend-icon="mdi-paperclip"
              hide-details="auto"
              @click="checkFile = ''"
              @change="inputChanged(inputSalesDataFile, 'sales')"
            ></v-file-input>
            <p class="red--text mt-2 ml-5" v-show="show2">
              {{ message2 }}
            </p>
          </v-col>
          <p class="red--text mt-2 ml-7">
            {{ checkFile }}
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click.stop="closeImportFileDialog"
            style="text-transform: unset !important"
          >
            {{$t('close')}}
          </v-btn>
          <v-btn
            color="primary"
            @click="importDataFile"
            style="text-transform: unset !important"
          >
            {{$t('import')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- !Import File Dialog -->
    <!-- Delete Item Dialog -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="500px"
      @click:outside="closeDialogs"
    >
      <v-card>
        <v-container class="pa-4">
          <v-row class="mb-4">
            <v-card-title class="text-h5">{{$t('delete')}}</v-card-title>
            <v-spacer />
            <v-btn icon color="primary" class="mt-3" @click="closeDialogs">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
          <v-divider />
          <div class="mx-4">
            <p class="my-6">{{$t('confirmStoreDelete')}}</p>
            <div class="form-layout mx-2">
              <v-row>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('storeCode')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputCode }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('storeName')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('city')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputCity }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('latLong')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    [ {{ formInputLat + ", " + formInputLng }} ]
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('company')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ formInputCompanyName }}
                  </span>
                </v-col>
                <v-col cols="4">
                  <span class="dialog-content-title"> {{$t('menu.analytics')}} : </span>
                </v-col>
                <v-col cols="8">
                  <span class="dialog-content-text">
                    {{ getAnalyticsText(formInputAnalytics) }}
                  </span>
                </v-col>
              </v-row>
            </div>
            <v-card-actions class="mt-4 mr-n4">
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="closeDialogs"
                style="text-transform: unset !important"
              >
                {{$t('close')}}
              </v-btn>
              <v-btn
                color="red lighten-1 white--text"
                style="text-transform: unset !important"
                class="mr-2"
                @click="deleteItemConfirm"
              >
                {{$t('delete')}}
              </v-btn>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- !Delete Item Dialog -->
  </div>
</template>
<script>
import AppError from "@/utils/AppError.js";
import ATextField from "@/components/ATextField.vue";
import ASelect from "@/components/ASelect.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "manage-stores",

  components: {
    ATextField,
    ASelect,
  },

  mounted: function () {
    this.loadData();
    this.loadCompanies();
  },

  mixins: [validationMixin],
  validations: {
    // Form validations
    formInputCode: { required },
    formInputName: { required },
    formInputCity: { required },
    formInputLat: { required },
    formInputLng: { required },
    formInputCompany: { required },
  },

  data() {
    return {
      // Dialogs
      formDialog: false,
      deleteDialog: false,
      importFileDialog: false,

      // Data table
      search: null,
      selectedIndex: -1,

      // Data
      data: [],
      analytics: ["Journey", "Heatmap", "Segments"],
      companies: [],
      show: false,
      show2: false,
      message2: "",
      message: "",
      checkFile: "",

      // Form data
      formInputCode: null,
      formInputName: null,
      formInputCity: null,
      formInputLat: null,
      formInputLng: null,
      formInputCompany: null,
      formInputCompanyName: null,
      formInputAnalytics: null,
      inputProductLocationsFile: null,
      inputSalesDataFile: null,

      // Data table
      sortBy: ["company.name", "name"],
      sortDesc: [false, false],
    };
  },

  computed: {
    // Return the form title depending on the index.
    formTitle() {
      return this.selectedIndex === -1
        ? this.$t("createStore")
        : this.$t("editStore");
    },
    headers() {
      return [
        { text: this.$t('company'), value: "company.name", width: 120 },
        {
          text: this.$t('code'),
          value: "code",
          width: 100
        },
        {
          text: this.$t('name'),
          value: "name",
        },
        {
          text: this.$t('city'),
          value: "city",
        },
        /*{
          text: "Lat Long",
          value: "latitude_longitude",
          filterable: false,
          sortable: false,
        },
*/
        {
          text: this.$t('menu.analytics'),
          value: "analytics",
        },
        {
          text: this.$t('files'),
          value: "files",
          align: "center",
          width: 50,
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('actions'),
          value: "actions",
          width: 250,
          align: "center",
          filterable: false,
          sortable: false,
        },
      ];
    },
  },

  methods: {
    /**
     * Remove chip from combobox.
     * @param {store} item - the store to remove.
     * @public
     */
    remove(item) {
      this.formInputAnalytics.splice(this.formInputAnalytics.indexOf(item), 1);
      this.formInputAnalytics = [...this.formInputAnalytics];
    },
    /**
     * Import files for store.
     * @param {store} item - the store.
     * @public
     */
    importFileItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.importFileDialog = true;
    },
    /**
     * Download the list of files.
     * @param {Array} files - the files to download.
     */
    downloadFiles(files) {
      function download_next(i) {
        if (i >= files.length) {
          return;
        }
        var a = document.createElement("a");
        a.href = files[i].url;
        a.target = "_parent";
        if ("download" in a) {
          a.download = files[i].title;
        }
        // Add a to the doc for click to work.
        (document.body || document.documentElement).appendChild(a);
        if (a.click) {
          a.click(); // The click method is supported by most browsers.
        } else {
          a.click(); // Backup using jquery
        }
        // Delete the temporary link.
        a.parentNode.removeChild(a);
        // Download the next file with a small timeout. The timeout is necessary
        // for IE, which will otherwise only download the first file.
        setTimeout(function () {
          download_next(i + 1);
        }, 500);
      }
      // Initiate the first download.
      download_next(0);
    },
    /**
     * Import files.
     * @public
     */
    importDataFile() {
      if (!this.inputProductLocationsFile && !this.inputSalesDataFile) {
        this.checkFile = this.$t("checkFile")
        return;
      }
       if (
        !this.inputSalesDataFile ||
        (this.inputSalesDataFile && this.inputSalesDataFile.length == 0)
      ) {
        this.checkFile = this.$t("checkFile");
        return;
      }
      if (this.message || this.message2) {
        return;
      }

      this.closeImportFileDialog();
      
      const item = this.data[this.selectedIndex];
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.importFiles;
      url = url.replace(":id", item.id);

      const formData = new FormData();
      if (this.inputProductLocationsFile) {
        formData.append("type", "product_location");
        formData.append("file", this.inputProductLocationsFile);
      }
      if (this.inputSalesDataFile) {
        formData.append("type", "sales_data");
        formData.append("file", this.inputSalesDataFile);
      }


      this.$api({
        url: url,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        method: "POST",
      })
        .then(() => {
          this.resetImportFileInputs();
          this.$store.dispatch(
            "alert/showSuccess",
            this.$t('filesImported')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
     /**
     * Close import files dialog.
     * @public
     */
    closeImportFileDialog() {
     
      this.importFileDialog = false;
      this.checkFile = "";
    },
    resetImportFileInputs(){
       this.inputProductLocationsFile = null;
      this.inputSalesDataFile = null;
    },
    /**
     * Handle file input change.
     * @param {file} inputFile - the file.
     * @param {string} type - the type of the file.
     * @public
     */
    inputChanged(inputFile, type) {
      if (inputFile) {
        if (
          inputFile.type.match(
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ) ||
          inputFile.type.match(".csv")
        ) {
          if (type == "products") {
            this.show = false;
          } else {
            this.show2 = false;
          }
        } else {
          if (type == "products") {
            this.show = true;
            this.message = this.$t("excelFile");
          } else {
            this.show2 = true;
            this.message2 = this.$t("excelFile");
          }
        }
      } else {
        if (type == "products") {
          this.show = false;
        } else {
          this.show2 = false;
        }
      }
    },
    /**
     * Get the stores.
     * @public
     */
    loadData() {
      // Load form data
      const loadFormData = this.$api({
        url: this.$config.api.route.stores,
        method: "GET",
      }).then((resp) => {
        this.data = resp.data;
      });
      // Async handle loads
      this.$store.dispatch("util/startLoading");
      Promise.all([loadFormData])
        .catch(() => {
          AppError.handle(new AppError("LOAD_DATA_UNKNOWN"));
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get the companies.
     * @public
     */
    loadCompanies() {
      this.$store.dispatch("util/startLoading");
      this.$api({
        url: this.$config.api.route.companies,
        method: "GET",
      })
        .then((resp) => {
          this.companies = resp.data;
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Set the edit dialog.
     * @param {store} item - the store.
     * @public
     */
    editItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.setForm(item);
      this.formDialog = true;
    },
    /**
     * Set the delete dialog.
     * @param {store} item - the store.
     * @public
     */
    deleteItem(item) {
      this.selectedIndex = this.data.indexOf(item);
      this.setForm(item);
      this.deleteDialog = true;
    },
    /**
     * Close all the dialogs.
     * @public
     */
    closeDialogs() {
      this.formDialog = false;
      this.deleteDialog = false;
    },
    resetInputFields(){
      this.$nextTick(() => {
        this.resetForm();
        this.selectedIndex = -1;
      });
    },
    /**
     * Set the store details.
     * @param {store} item - the store.
     * @public
     */
    setForm(item) {
      let company = this.companies.find(
        (company) => company.name === item.company.name
      );
      this.formInputCompany = company;
      this.formInputCompanyName = company.name;
      this.formInputName = item.name;
      this.formInputCity = item.city;
      this.formInputLat = item.latitude_longitude[0];
      this.formInputLng = item.latitude_longitude[1];
      this.formInputCode = item.code;
      this.formInputAnalytics = item.analytics;
    },
    /**
     * Reset the form dialog.
     * @public
     */
    resetForm() {
      this.formInputCompany = null;
      this.formInputCompanyName = null;
      this.formInputName = null;
      this.formInputCity = null;
      this.formInputLat = null;
      this.formInputLng = null;
      this.formInputCode = null;
      this.formInputAnalytics = null;
      this.$v.$reset();
    },
    /**
     * Create or edit a store.
     * @public
     */
    formSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      let company = null;

      if (typeof this.formInputCompany === "object") {
        company = this.companies.find(
          (company) => company.name === this.formInputCompany.name
        );
      } else {
        company = this.companies.find(
          (company) => company.name === this.formInputCompany
        );
      }

      const item = this.data[this.selectedIndex];
      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.stores;
      if (item !== undefined) {
        url = this.$config.api.route.storesAction;
        url = url.replace(":id", item.id);
      }

      var latLong = [];
      latLong.push(this.formInputLat);
      latLong.push(this.formInputLng);

      this.closeDialogs();

      this.$api({
        url: url,
        data: {
          code: this.formInputCode,
          name: this.formInputName,
          company: company.id,
          city: this.formInputCity,
          latitude_longitude: latLong,
          analytics: this.formInputAnalytics,
        },
        method: item === undefined ? "POST" : "PUT",
      })
        .then(() => {
          this.resetInputFields();
          this.loadData();

          this.$store.dispatch(
            "alert/showSuccess",
            item === undefined
              ? this.$t('storeCreated')
              : this.$t('storeEdited')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Delete store.
     * @public
     */
    deleteItemConfirm() {
      const item = this.data[this.selectedIndex];

      this.closeDialogs();

      this.$store.dispatch("util/startLoading");
      let url = this.$config.api.route.storesAction;
      url = url.replace(":id", item.id);
      this.$api({
        url: url,
        method: "DELETE",
      })
        .then(() => {
          this.resetInputFields();
          this.loadData();

          this.$store.dispatch(
            "alert/showSuccess",
            this.$t('storeDeleted')
          );
        })
        .catch((err) => {
          AppError.handle(err);
        })
        .finally(() => {
          this.$store.dispatch("util/stopLoading");
        });
    },
    /**
     * Get analytics text formated.
     * @param {array} analytics - The analytics.
     * @public
     */
    getAnalyticsText(analytics) {
      if (analytics != null) {
        var analyticsText = "";
        analytics.forEach((analytic) => {
          analyticsText += analytic + "; ";
        });
        return analyticsText;
      }
    },
  },
};
</script>
